/* Import Statements for Fonts and Icons */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

:root {
  --button-primary: #CB2D3E;
  --button-hover: #d93d4e;
  --font-primary: 'Inter', sans-serif;
  --transition-speed: 0.3s;
  --card-opacity: 0.85;
  --border-radius: 12px;
  --mobile-spacing: 16px;
  --button-touch-size: 44px; /* Minimum recommended touch target size */
  --vh: 1vh;
  
  /* Dark theme colors as default */
  --bg-primary: #1e1e1e;
  --bg-secondary: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #e0e0e0;
  --border-color: #404040;
  --border-inverse-color: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --hover-bg: #1e1e1e;
  --table-header-bg: #1e1e1e;
  --table-row-hover: #3d3d3d;
  --input-bg: #2d2d2d;
  --button-text: #ffffff;
  --card-bg: #2d2d2d;
  --error-color: #ff6b6b;
  --modal-backdrop: rgba(0, 0, 0, 0.7);
  --bg-error: #3d2929;
  --bg-disabled: #3d3d3d;
}

/* Reset and base styles */
*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  /* Use the custom property to ensure correct height on mobile */
  min-height: 100vh; /* Fallback */
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: white;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
  position: relative;
  overflow-x: hidden;
  padding: 150px 16px 100px;
}

/* Content container */
.content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  margin: 0 auto;
  margin-top: 20px;
}

/* Header Styles - Adjusted for better positioning */
.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2.5rem;
  width: 100%;
  padding-top: 20px;
}

.company-name {
  font-size: min(4.5rem, 12vw);
  color: var(--button-primary);
  font-weight: 800;
  letter-spacing: 0.1em;
  line-height: 1;
  margin-bottom: 0.1em;
  text-transform: uppercase;
  margin-top: 10px;
}

.title {
  font-variant: small-caps;
  font-size: min(2.5rem, 7vw);
  margin: 0;
  line-height: 1;
  letter-spacing: 0.15em;
  font-weight: 300;
  opacity: 0.9;
}

/* Mission Statement Styles */
.mission-statement {
  margin: 0 auto 2rem;
  width: 100%;
  position: relative;
}

.mission-content {
  position: relative;
  padding: min(2.5rem, 6vw);
  border-radius: var(--border-radius);
  background: rgba(0, 0, 0, var(--card-opacity));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.mission-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
}

.mission-content h2 {
  font-size: min(2rem, 6vw);
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--button-primary);
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.mission-content h2::after {
  content: '';
  position: absolute;
  width: min(60px, 15vw);
  height: 3px;
  background-color: var(--button-primary);
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.mission-content p {
  font-size: min(1.5rem, 4.5vw);
  line-height: 1.6;
  margin: 25px 0 0;
  color: white;
  font-weight: 400;
}

/* Core Values Styles - Better responsive grid */
.core-values {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  gap: min(1.5rem, 4vw);
  margin: 0 auto 2.5rem;
  width: 100%;
}

.value-card {
  background: rgba(0, 0, 0, var(--card-opacity));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius);
  padding: min(2rem, 5vw) min(1.5rem, 4vw);
  text-align: center;
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.value-card h3 {
  font-size: min(1.5rem, 4.5vw);
  color: white;
  margin: 0 0 1.5rem 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  padding-bottom: 15px;
}

.value-card h3::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 2px;
  background-color: var(--button-primary);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.value-card p {
  font-size: min(1.1rem, 3.8vw);
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
}

/* Button Styles */
.button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: min(1.5rem, 4vw);
  margin: 2rem 0;
  width: 100%;
}

.access-button, .careers-link, .job-openings-button {
  background-color: var(--button-primary);
  color: white;
  padding: 0.9rem 1.8rem;
  border-radius: var(--border-radius);
  text-decoration: none;
  font-weight: 600;
  font-size: min(1rem, 3.8vw);
  transition: all var(--transition-speed);
  box-shadow: 0 4px 12px rgba(203, 45, 62, 0.3);
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  letter-spacing: 0.5px;
}

.access-button:hover, .careers-link:hover, .job-openings-button:hover {
  background-color: var(--button-hover);
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(203, 45, 62, 0.4);
}

/* Login Buttons - Fixed positioning with better padding */
.login-button-container {
  position: fixed;
  top: 25px;
  right: 20px;
  z-index: 100;
  display: flex;
  gap: 12px;
}

.login-button, .manage-button {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.login-button:hover, .manage-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-2px);
}

/* Contact Container - Fixed positioning */
.contact-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.contact-link {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.9rem;
}

/* Loading Spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 9999;
}

.spinner {
  border: 3px solid rgba(255, 255, 255, .1);
  width: min(40px, 10vw);
  height: min(40px, 10vw);
  border-radius: 50%;
  border-left-color: var(--button-primary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

/* Careers Page - Adjust for better spacing */
.careers-page {
  background: rgba(0, 0, 0, var(--card-opacity));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: min(3rem, 6vw) min(2rem, 5vw);
  border-radius: var(--border-radius);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
  margin: auto;
  width: 100%;
  max-width: 700px;
  transition: opacity 0.5s ease-out;
  opacity: 0;
}

.careers-page.fade-in {
  opacity: 1;
}

.careers-page h1 {
  font-size: min(2.5rem, 7vw);
  margin-bottom: 1.5rem;
  color: var(--button-primary);
  font-weight: 700;
}

.career-description {
  color: white;
  font-size: min(1.3rem, 4vw);
  line-height: 1.7;
  margin-bottom: 2rem;
}

.footer .home-button {
  background: none;
  border: none;
  color: white;
  font-size: min(1rem, 3.8vw);
  padding: 0.8rem;
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
  opacity: 0.8;
  transition: opacity var(--transition-speed);
  text-decoration: none;
}

.footer .home-button:hover {
  opacity: 1;
}

.footer .home-button i {
  margin-right: 0.5rem;
}

/* Video background */
.video-background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.video-fill {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

/* Media queries - Adjusted for better breakpoints */
@media (max-width: 768px) {
  .App {
    padding: 130px 16px 100px;
  }
  
  .button-container {
    flex-direction: column;
    gap: 1rem;
  }
  
  .access-button, .careers-link, .job-openings-button {
    width: 100%;
    max-width: 220px;
  }
  
  .header-title {
    margin-bottom: 1.5rem;
  }
  
  .mission-statement {
    margin-bottom: 1.5rem;
  }
  
  .core-values {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* Adjust login buttons for mobile */
  .login-button-container {
    top: 20px;
    right: 16px;
  }
  
  .login-button, .manage-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
  
  .contact-container {
    bottom: 16px;
    right: 16px;
  }
  
  .contact-link {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .App {
    padding-top: 100px;
    padding-bottom: 90px;
  }
  
  /* Stack buttons vertically on very small screens if needed */
  .button-container {
    flex-direction: column;
    gap: 12px;
  }
  
  .access-button, .careers-link {
    width: 100%;
    max-width: 200px;
  }
}

/* Mobile-friendly improvements */

/* Base styles */
:root {
  --button-primary: #CB2D3E;
  --button-hover: #d93d4e;
  --font-primary: 'Inter', sans-serif;
  --transition-speed: 0.3s;
  --card-opacity: 0.85;
  --border-radius: 12px;
  --mobile-spacing: 16px;
  --button-touch-size: 44px; /* Minimum recommended touch target size */
  --vh: 1vh;
}

/* Make sure elements have enough touch area on mobile */
.login-button-container {
  position: fixed;
  top: 25px;
  right: 20px;
  z-index: 100;
  display: flex;
  gap: 12px;
}

.login-button, .manage-button {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 10px 16px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  min-height: var(--button-touch-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Improve button container spacing for mobile */
.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.access-button, .careers-link, .job-openings-button {
  display: inline-block;
  background-color: var(--button-primary);
  color: white;
  padding: 12px 20px;
  border-radius: var(--border-radius);
  text-decoration: none;
  font-weight: 600;
  transition: all var(--transition-speed) ease;
  min-width: 140px;
  min-height: var(--button-touch-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Contact link improvements */
.contact-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.contact-link {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 10px 16px;
  border-radius: 8px;
  min-height: var(--button-touch-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Improve card readability on mobile */
.value-card {
  background: rgba(0, 0, 0, var(--card-opacity));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: clamp(1.25rem, 4vw, 2rem) clamp(1rem, 3vw, 1.5rem);
  border-radius: var(--border-radius);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  flex: 1;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .core-values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
    width: 100%;
    gap: 20px;
    margin-bottom: 2.5rem;
  }
  
  .mission-content {
    width: 100%;
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .App {
    padding: 130px 16px 100px;
  }
  
  .header-title {
    margin-bottom: 1.5rem;
  }
  
  .company-name {
    font-size: clamp(3rem, 10vw, 4.5rem);
  }
  
  .title {
    font-size: clamp(1.2rem, 4vw, 1.8rem);
  }
  
  .mission-statement {
    margin-bottom: 1.5rem;
  }
  
  .mission-content {
    padding: clamp(1.5rem, 6vw, 2.5rem) clamp(1.2rem, 4vw, 2rem);
  }
  
  .mission-content h2 {
    font-size: clamp(1.5rem, 7vw, 2.5rem);
  }
  
  .mission-content p {
    font-size: clamp(1rem, 4vw, 1.3rem);
  }
  
  .value-card h3 {
    font-size: clamp(1.1rem, 5vw, 1.4rem);
  }
  
  .value-card p {
    font-size: clamp(0.9rem, 3.5vw, 1rem);
  }
  
  /* Improve button layout on mobile */
  .button-container {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  .access-button, .careers-link, .job-openings-button {
    width: 100%;
    max-width: 220px;
  }
  
  /* Better positioning for fixed elements */
  .login-button-container {
    top: 20px;
    right: 16px;
  }
  
  .login-button, .manage-button {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
  
  .contact-container {
    bottom: 16px;
    right: 16px;
  }
  
  .contact-link {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
  
  /* Fix scrolling on mobile */
  body, html {
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .App {
    padding-top: 100px;
    padding-bottom: 90px;
  }
  
  /* Increase padding for small screens */
  .value-card {
    padding: 1.25rem 1rem;
  }
  
  /* Smaller buttons for very small screens */
  .login-button, .manage-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }
  
  /* Stack buttons with full width */
  .button-container {
    width: 100%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .access-button, .careers-link, .job-openings-button {
    width: 100%;
  }
  
  /* Adjust spacing */
  .core-values {
    gap: 16px;
    margin-bottom: 1.5rem;
  }
  
  /* Prevent text overflow */
  .mission-content p, .value-card p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }
}

/* Portrait orientation specific adjustments */
@media (max-height: 600px) {
  .App {
    padding-top: 90px;
  }
  
  .header-title {
    margin-bottom: 1rem;
  }
  
  .login-button, .manage-button {
    padding: 6px 10px;
    font-size: 0.75rem;
  }
}

/* Better touch interactions for mobile */
@media (hover: none) {
  .login-button:active, .manage-button:active, 
  .access-button:active, .careers-link:active, 
  .job-openings-button:active {
    transform: scale(0.95);
  }
}

/* Touch feedback styles */
.touch-active {
  transform: scale(0.95);
  opacity: 0.9;
}

/* Better touch targets */
button, a {
  touch-action: manipulation;
}
