.video-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.video-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

/* Animation for subtle zoom effect */
@keyframes slowZoom {
  0% { transform: scale(1.01); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1.01); }
}

.animate-zoom {
  animation: slowZoom 60s infinite ease-in-out;
}
